import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { respondTo } from "../_respondTo"

import { FiArrowDownCircle } from "react-icons/fi"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "../components/section"

import BackgroundSlider from "gatsby-image-background-slider"

import TwmButton from "../components/twm-button"

import scrollTo from "gatsby-plugin-smoothscroll"

import { palette } from "../_variables"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
`

const WrapperBottom = styled.div`
  width: 100%;
  position: relative;
  left: 0;
  top: 0;
  background-color: ${palette.dark};
  color: ${palette.darkContrast};

  h2 {
    color: white;
  }

  a {
    color: white;
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }
  }
`

const StrapLine = styled.div`
  top: 20vh;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 1em;
  text-align: center;
  color: white;
  position: absolute;
  max-width: 960px;

  h1 {
    text-transform: uppercase;
  }

  ${respondTo.sm`
    top: 40vh;
  `}
`

const ScrollButton = styled.a`
  color: white;
  font-size: 30px;
  border-radius: 30px;
  position: absolute;
  bottom: 5%;

  ${respondTo.md`
  font-size: 40px;
  border-radius: 40px;

`}

  :hover {
    background-color: ${palette.primary};
  }

  transition: background-color 1s ease;
`

const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;

  ${respondTo.md`
    flex-direction: row;

`}
`

const FlexItem = styled.div`
  margin-top: 7vh;
  flex: 1 1 0px;
  margin: 2em;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
`

const IndexPage = props => (
  <Layout firmName={props.pageContext.firm} firmWeb={props.pageContext.web}>
    <SEO
      title="Home"
      keywords={[
        `TAG Financial Planning`,
        `TAG FP`,
        `Financial Planning`,
        `Wealth Management`,
        `Financial Advice`,
        `Pensions`,
        `Investments`,
        `Financial Protection`,
      ]}
    />
    <Wrapper>
      <BackgroundSlider
        query={useStaticQuery(graphql`
          query {
            backgrounds: allFile(
              filter: { sourceInstanceName: { eq: "backgrounds" } }
            ) {
              nodes {
                relativePath
                childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        `)}
        initDelay={2} // delay before the first transition (if left at 0, the first image will be skipped initially)
        transition={2} // transition duration between images
        duration={8} // how long an image is shown
        // specify images to include (and their order) according to `relativePath`
        images={["man-on-rock.jpg", "family.jpg", "mountain-sunset.jpg"]}
        alt=""
        // pass down standard element props
      >
        {/* Captions in sync with background images*/}

        <StrapLine>
          <h1>Helping you make smart financial choices</h1>
          Alongside your accountant we will work with you in order to achieve
          your goals. We take the time to understand what is important to you,
          what your plans for the future are and ensure you have enough to make
          it happen. Life can throw many obstacles in the way, we aim to clear
          the path and act as your guide.
        </StrapLine>
        <StrapLine>
          <h1>Your life, your family, your business, your path</h1>
          Your goals will be unique to you and therefore so should your
          financial plan. Whether you are focussing on growing your wealth,
          protecting your wealth, or ensuring your business interests are
          protected and tax efficient, it is essential that we provide a bespoke
          service for you that covers all aspects of your life.
        </StrapLine>

        <StrapLine>
          <h1>Building a brighter future doesn't have to be taxing</h1>
          Your goals will be unique to you and therefore so should your
          financial plan. Whether you are focussing on growing your wealth,
          protecting your wealth, or ensuring your business interests are
          protected and tax efficient, it is essential that we provide a bespoke
          service for you that covers all aspects of your life.
        </StrapLine>
      </BackgroundSlider>
      <ScrollButton onClick={() => scrollTo("#first")}>
        <FiArrowDownCircle></FiArrowDownCircle>
      </ScrollButton>
    </Wrapper>

    <div style={{ height: "100vh" }}></div>

    <WrapperBottom id={"first"}>
      <Section type="wide" color="dark">
        <FlexBox>
          <FlexItem>
            <h2>About you</h2>
            <p>
              It's all about you. Your own, your family's, and if you are a
              business owner, your business's financial futures are all
              interwoven together. We will take time to really understand these
              intricacies, to ensure your financial plan matches your
              requirements, but is also flexible...
            </p>
            <div style={{ marginTop: "auto" }}>
              <Link to={props.pageContext.firm + "/about-you"}>
                Read more...
              </Link>
            </div>
          </FlexItem>
          <FlexItem>
            <h2>Who are we</h2>
            <p>
              You work hard for your money. It is important that your money
              works hard for you. Our team of experienced independent Financial
              Planners will work alongside your accountant to assist you with
              all your financial planning needs. As your accountant works with
              you...
            </p>
            <div style={{ marginTop: "auto" }}>
              <Link to={props.pageContext.firm + "/who-are-we"}>
                Read more...
              </Link>
            </div>
          </FlexItem>
          <FlexItem>
            <h2>Contact us</h2>
            <p>
              To arrange an initial meeting without cost or obligation please
              get in touch
            </p>
            <p>
              Tel: 0330 350 3215
              <br />
              Email: info@tagfp.co.uk
            </p>
            <div style={{ marginTop: "auto" }}>
              <Link to={props.pageContext.firm + "/contact-us"}>
                Get in touch...
              </Link>
            </div>
          </FlexItem>
        </FlexBox>
      </Section>
    </WrapperBottom>
  </Layout>
)

export default IndexPage
